import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import StoreBadge from 'react-store-badge';

import Dord1 from "../../images/dordrecht2.jpg";
import Amst1 from "../../images/amsterdam.jpg";
import Hard1 from "../../images/hardenberg.jpg";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;


const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

export default () => {
  const cards = [
    {
      imageSrc:Dord1,
      subtitle: "App (NL/EN)",
      title: "Dordrecht",
      description:
      //deploy: text tweaked
        "On behalf of the municipality of Dordrecht, we developed an exciting open-air escape room surrounding the infamous flood of 1421 that affected the city largely. While going on quests and roaming around the city you discover a lot about the love-hate relationship Dordrecht has with water. The app can be used in English and Dutch.",
        google: "https://play.google.com/store/apps/details?id=com.dordrecht&gl=NL",
        apple: "https://apps.apple.com/fi/app/save-the-city-dordrecht/id1535039713",
      linkText: "Download the app"
    },
    {
      imageSrc:Hard1,
      subtitle: "App (NL)",
      title: "Hardenberg",
      description:
        "You can now explore Hardenberg using our app. Discover its rich history of resistance, major challenges and togetherness. Five exciting quests are ready for you to discover. The app is in Dutch.",
        google: "https://play.google.com/store/apps/details?id=com.savethecity&gl=NL",
        apple: "https://apps.apple.com/vn/app/save-the-city-hardenberg/id1628084231",
        // added linkText, keeping it empty removes the download link for Amsterdam
        linkText: "Download the app" 
    },
    {
      imageSrc:Amst1,
      subtitle: "App (EN)",
      title: "Amsterdam",
      description:
        "Our own ongoing project is set in the beautiful city of Amsterdam. You will visit famous and less famous locations and certainly discover new and surprising things about the city. It will all be set in the theme of Save the City. Fun for both locals and tourists. The app will be in English.",
        google: "",
        apple: "",
        // added linkText, keeping it empty removes the download link for Amsterdam
        linkText: ""
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Cities</HeadingTitle>
          <HeadingDescription>
          Every city has its own history and its own hidden gems. See our projects below. Contact us if you like an open-air escape room for your own city. 
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>{card.linkText}</Link> */}
                <Paragraph>
                  <StoreBadge
                  name={card.title}
                  googlePlayUrl={card.google}
                  appStoreUrl={card.apple}
                  />
                </Paragraph>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
    </Container>
  );
};
