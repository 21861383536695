// Run yarn build before firebase deploy! Otherwise the website won't update.

import React from "react";
import "style.css";
import "tailwindcss/dist/base.css";
import Hero from "components/hero/ICDhomepage";
import CitiesCards from "components/features/ICDcities_cards";
import TeamCards from "components/cards/ICDteam";
import Forms from "components/forms/ICDcontactform";
import Footer from "components/footers/ICDfooter";
import Header from "components/headers/light.js";
// @Bas, why is git imported here? It seems like it is never used. Can we remove the package? (yarn remove)
import {git,
  BrowserRouter as Router,
  Route, 
  Switch, 
} from "react-router-dom";

function App({ roundedHeaderButton }) {
  
  return (
    <Router>
    <Header roundedHeaderButton={roundedHeaderButton} />
      <div className="App">
        <Switch>
          <Route path="/" exact component={Hero} />
          <Route path="/Cities" exact component={CitiesCards} />
          <Route path="/About" exact component={TeamCards} />
          <Route path="/Contact" exact component={Forms} />
        </Switch>
      </div>
    <Footer />
    </Router>
  );
}

export default App