import React from "react";
// import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";


// import Header from "../headers/light.js";

import Mockups from "../../images/mockups.svg";
import StoreBadge from 'react-store-badge';

// Commented out the old download images, but layout has changed
// react-store-badges also had vulnerabilities in npm
// import ReactStoreBadges from 'react-store-badges'

// added px-8 to fix margin on medium screens
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto px-8 lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;


// const Actions = styled.div`
//   ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
//   input {
//     ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
//   }
//   button {
//     ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
//   }
// `;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;


//deploy: Text Paragraph tweaked 
export default () => {
  return (
    <>
      
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Save the <span tw="text-primary-500">City</span>
            </Heading>
            <Paragraph>
            Explore, puzzle, solve mysteries and Save the City from lurking dangers. Use the app to discover fascinating locations while doing fun quests. Meanwhile, you unravel the hidden histories. The entire city turns into an escape room!  
            </Paragraph>
            <Paragraph>
              <Link href="/Cities" >Download the app</Link>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={Mockups} alt="3 phones with images of the app" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
