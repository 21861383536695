import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
// import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
// import { ReactComponent as GithubIcon } from "images/github-icon.svg";
import Stan from "../../images/Stanley.png";
import Bas from "../../images/Bas.jpg";
import Lucas from "../../images/Lucas.jpg";


const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto px-8 text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
  heading = "Meet The Team",
  subheading = "About Us",
  //deploy: text altered
  description = "The three of us are big fans of puzzling, solving riddles and doing escape rooms. At the same time, we love being outside to discover new places. With our app you can go outside and experience a city as if you’re walking through an escape room. We develop apps on request, so we can provide a unique experience tailored to your city. The experience for Dordrecht is already available to you! Amsterdam is our personal ongoing project.",
  cards = [
    {
      imageSrc: Stan,
      position: "CEO & Product Manager",
      name: "Stanley Kelder",
      links: [
        // {
          // url: "https://twitter.com",
          // icon: TwitterIcon,
        // },
        {
          url: "https://www.linkedin.com/in/stanleykelder/",
          icon: LinkedinIcon,
        },
        // {
          // url: "https://github.com",
          // icon: GithubIcon,
        // },
      ],
    },
    {
      imageSrc: Bas,
      position: "Creative Director & Innovator",
      name: "Bas Kluft",
      links: [
        // {
        //   url: "https://twitter.com",
        //   icon: TwitterIcon,
        // },
        {
          url: "https://www.linkedin.com/in/bas-kluft-9441076a/",
          icon: LinkedinIcon,
        },
        // {
        //   url: "https://github.com",
        //   icon: GithubIcon,
        // },
      ],
    },
    {
      imageSrc: Lucas,
      position: "Content Manager & Operations Lead",
      name: "Lucas Lumeij",
      links: [
        // {
        //   url: "https://twitter.com",
        //   icon: TwitterIcon,
        // },
        {
          //deploy: simple url
          url: "https://www.linkedin.com/in/lucaslumeij",
          icon: LinkedinIcon,
        },
        // {
        //   url: "https://github.com",
        //   icon: GithubIcon,
        // },
      ],
    },
    
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading> }
          {description && <Description>{description}</Description> }
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url}>
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
