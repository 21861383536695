// import firebase from "firebase/app";
// import "firebase/database";

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const config = {
//     apiKey: "AIzaSyAfG7gHOBBaYgGj32dHRE2S87dEswSs-O8",
//     authDomain: "websiteicd-496a1.firebaseapp.com",
//     databaseURL: "https://websiteicd-496a1-default-rtdb.europe-west1.firebasedatabase.app/",
//     projectId: "websiteicd-496a1",
//     storageBucket: "websiteicd-496a1.appspot.com",
//     messagingSenderId: "873619965506",
//     appId: "1:873619965506:web:506c1cc1c37c85d0ff4fdb",
//     measurementId: "G-3GBS3C4C9S"
//   };

// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { getFirestore } from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: "AIzaSyAKxTLSjQhTh8Jo3eZ2Bvaw3NMjl6QLz-Y",
  authDomain: "savethecity-app.firebaseapp.com",
  projectId: "savethecity-app",
  storageBucket: "savethecity-app.appspot.com",
  messagingSenderId: "434608771871",
  appId: "1:434608771871:web:c4542b88bfc2e42831162a",
  measurementId: "G-0LWLJHKE9Z"
};

// Initialize Firebase
const app = initializeApp(config);
// export const db = getFirestore(app);
// export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
export const database = getFirestore(app);