import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import ContactUsSrc from "images/contactus.svg";

// firebase database for getting form info
import { collection, addDoc } from "@firebase/firestore";
import { database } from "../../firebase-config.js";
// const db = firebase.firestore();

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

// added px-8 to fix margin on medium screens
const Subheading = tw(SubheadingBase)`text-center mx-auto px-8 md:text-left`;
const Heading = tw(SectionHeading)`mt-4 mx-auto px-8 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 mx-auto px-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto px-8 md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`${tw`h-24`}`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`



const timestamp = Date.now();

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "Interested in an escape room experience for your city? A quesion about the app? Or maybe just a compliment? Use the form to tell us. We will be happy to hear from you!",
  submitButtonText = "Send",
  textOnLeft = true,
  sent = "Thank you for your message!"
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  // DEPLOY
  // state constants to store mail components
  const [senderName, setSenderName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [formSent, setFormSent] = useState(false);

const handleSubmit = (evt) => {
  evt.preventDefault();
  writeToDatabase();
  // writeMail();
  // alert('Thank you for your message!');
  setFormSent(true);
}

const resetForm = () => {
  setFormSent(false);
  setSenderName("");
  setEmail("");
  setSubject("");
  setMessage("");
}


const writeToDatabase = async (e) => {
  try {
      const docRef = await addDoc(collection(database, "website-contact"), {
        to: 'hello@savethecity.app',
        replyTo: email,
        message: {
          subject: subject,
          text: "from: " + senderName + "\nmessage: " + message,
        } 
      });
      console.log("Document written with ID: ", docRef.id);

    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Something went wrong with the automatic form. Please send your message to hello@savethecity.app");
    }
}

  if (formSent) {
    return (
      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={ContactUsSrc} />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {description && <Description>{description}</Description>}

              {sent && <Description>{sent}</Description>}

          
              <SubmitButton type="button" onClick={() => resetForm()}>{"Send another message"}</SubmitButton>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    );
  }
  else {
    return (
      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={ContactUsSrc} />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {description && <Description>{description}</Description>}
              
              <Form  onSubmit={handleSubmit}>
                <Input type="email" name="email" value={email} placeholder="Your Email Address" onChange={e => setEmail(e.target.value)}/>
                <Input type="text" name="sender" value={senderName} placeholder="Full Name" onChange={e => setSenderName(e.target.value)}/>
                <Input type="text" name="subject" value={subject} placeholder="Subject" onChange={e => setSubject(e.target.value)}/>
                <Textarea type="text" name="message" value={message} placeholder="Message" onChange={e => setMessage(e.target.value)}/>
                <SubmitButton type="submit">{submitButtonText}</SubmitButton>
              </Form>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    );
  }
};
